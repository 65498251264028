<template>
	<div class="homeSet">
		<div class="homeSet-box">
			<div class="box-item">
				<div class="item-title">显示</div>
				<el-switch v-model="open_bulletin" active-color="#fa8919"></el-switch>
			</div>
		</div>
		<div class="homeSet-box" v-if="open_bulletin == true">
			<div class="box-item">
				<div class="item-title">公告通知</div>
				<el-input type="textarea" autosize placeholder="请输入内容" v-model="bulletin" maxlength="300" show-word-limit>
				</el-input>
			</div>
		</div>
		<div class="homeSet-footer">
			<el-button type="warning" @click.stop="confirmFn">确 定</el-button>
		</div>
	</div>
</template>
   
<script>
export default {
	data() {
		return {
			open_bulletin: true,
			bulletin: ''
		};
	},
	mounted() {
		this.getList()
	},
	methods: {
		getList() {
			this.$http.post(`/erp/v1/home/get_homepage_set`).then((res) => {
				const {
					code,
					data,
					msg
				} = res.data
				this.loading = false
				if (code == 200) {
					data.open_bulletin == 0 ? this.open_bulletin = false : this.open_bulletin = true
					this.bulletin = data.bulletin
				} else {
					this.$message.error(msg);
				}
			});
		},
		// 确定
		confirmFn() {
			this.$http.post(`/erp/v1/home/save_bulletin`, {
				bulletin: this.bulletin,
				open_bulletin: this.open_bulletin == true ? 1 : 0,
			}).then((res) => {
				const {
					code,
					data,
					msg
				} = res.data
				this.loading = false
				if (code == 200) {
					this.$message.success(msg);
					this.getList()
				} else {
					this.$message.error(msg);
				}
			});
		},
	},
};
</script>
<style lang="scss" scoped>
.homeSet {
	.homeSet-box {
		background: #fff;
		border-radius: 6px;
		padding: 20px;
		margin-bottom: 20px;

		.box-item {
			display: flex;
			align-items: center;

			.item-title {
				width: 100px;
				font-size: 14px;
			}

			.item-b {
				display: flex;
				flex-direction: column;
			}
		}
	}

	.homeSet-footer {
		display: flex;
		justify-content: center;

	}

}
</style>